import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/menus/Nav/Nav";
import Footer from "./components/menus/Footer/Footer";

const Home = React.lazy(() => import("./pages/Home/Home"));
const Card = React.lazy(() => import("./pages/Card/Card"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const About = React.lazy(() => import("./pages/About/About"));
const Services = React.lazy(() => import("./pages/Services/Services"));

const NotFound = () => {
  return (
    <div className="main">
      <div className="page-not-found">
        <header style={{ textAlign: "center" }}>404 - Page Not Found</header>
        <div className="overview" style={{ textAlign: "center" }}>
          The page you are looking for does not exist.
        </div>
      </div>
    </div>
  );
};

const ComingSoon = () => {
  return (
    <div className="main">
      <div className="page-not-found">
        <header style={{ textAlign: "center" }}>Page Under Construction</header>
        <div className="overview" style={{ textAlign: "center" }}>
          I am working on the content of this page. Please check back soon!
        </div>
      </div>
    </div>
  );
};

const routes = [
  { path: "/", mainClass: "-home", Component: Home },
  { path: "/home", mainClass: "-home", Component: Home },
  { path: "/card", mainClass: "-card", Component: Card },
  { path: "/about", mainClass: "-about", Component: About },
  { path: "/projects", mainClass: "404", Component: ComingSoon },
  { path: "/services", mainClass: "-services", Component: Services },
  { path: "/contact", mainClass: "-contact", Component: Contact },
  { path: "*", mainClass: "404", Component: NotFound },
];

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Nav />
        <Suspense
          fallback={
            <div className="main">
              <div className="page">
                <div className="loading">Loading...</div>
              </div>
            </div>
          }
        >
          <Routes>
            {routes.map(({ path, mainClass, Component }) => (
              <Route
                path={path}
                key={path}
                element={
                  <div className={`page${mainClass}`}>
                    <Component />
                  </div>
                }
              />
            ))}
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;
