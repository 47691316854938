import { useState, useEffect } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";

function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    document.body.addEventListener("click", closeMobileMenu);
    return () => {
      document.body.removeEventListener("click", closeMobileMenu);
    };
  }, []);

  const handleBurgerClick = (e) => {
    // Prevent the body click event from being triggered when clicking the burger icon
    e.stopPropagation();
    toggleMobileMenu();
  };

  return (
    <nav className="main-nav">
      <div className="nav-menu">
        <Link to="/about" className="desktop-link">
          About
        </Link>
        <Link to="/projects" className="desktop-link">
          Projects
        </Link>
        <Link to="/home" className="logo-link">
          <img
            src="../../img/logo.webp"
            alt="DGW Interactive"
            className="logo"
          />
        </Link>
        <Link to="/services" className="desktop-link">
          Services
        </Link>
        <Link to="/contact" className="desktop-link contact">
          Contact
        </Link>

        <img
          src="../../img/burger-icon.png"
          alt="Burger Icon"
          className="burger-icon"
          onClick={handleBurgerClick}
        />
        {mobileMenuOpen && (
          <div className="mobile-menu">
            <Link to="/home" className="logo-link">
              <img
                src="../../img/logo.webp"
                alt="DGW Interactive"
                className="logo"
              />
            </Link>
            <Link
              to="/about"
              className="mobile-link"
              onClick={toggleMobileMenu}
            >
              About
            </Link>
            <Link
              to="/projects"
              className="mobile-link"
              onClick={toggleMobileMenu}
            >
              Projects
            </Link>
            <Link
              to="/services"
              className="mobile-link"
              onClick={toggleMobileMenu}
            >
              Services
            </Link>
            <Link
              to="/contact"
              className="mobile-link contact"
              onClick={toggleMobileMenu}
            >
              Contact
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Nav;
